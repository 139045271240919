import React from 'react'
import './WorkBanner.css';
import {Link} from 'react-router-dom';
import appImg from '../media/honed/HangboardHold.png';

export default function WorkBanner() {
    return (
        <div className='WorkContainer'>
            <h6 className='SmallTitle orangeText'>OUR WORK</h6>
            <div className='TwoColumns'>
                <div className='LeftColumn'>
                    <h2 >A hangboard training <br></br>
                      app for climbers.
                    </h2>
                    <p className='workDescription orangeText'>
                        Honed allows climbers to customize their hangboard
                        training by selecting hangboard holds, fingers,
                        hang duration and rest time for a personalized
                        strength building workout.
                    </p>
                    <div className='TwoColList'>
                        <div className='LeftColList'>
                            <ul className='noDisk'>
                                <li className='listTitle'>
                                    <p>PERFORMANCE</p>
                                </li>
                                <li className='listItem'>
                                    <p>UX Design</p>
                                </li>
                                <li className='listItem'>
                                    <p>Front end dev</p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul className='noDisk'>
                                <li className='listTitle'>
                                    <p>BEAUTY</p>
                                </li>
                                <li className='listItem'>
                                    <p>Logo</p>
                                </li>
                                <li className='listItem'>
                                    <p>UI Design</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='PurpleBtnDiv'>
                        <Link className='PurpleButton' to='work'>Our Work</Link>
                    </div>
                </div>
                <div className='RightColumn'>
                    <img className='appImg' src={appImg} alt='Hangboard screen shot'></img>
                </div>
            </div>
        </div>
    );
}