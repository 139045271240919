import React from 'react'
import './ProcessMainBanner.css';
import TitleText from '../Text/TitleText';
import * as constants from '../../Constants';

export default function ProcessMainBanner() {
    return (
        <div className="processMainContainer">
            <div id='processMainText'>
                <TitleText inputColor={constants.ORANGE}>
                    Our process from <br></br> sketch to launch.
                </TitleText>
            </div>
        </div>
    );
}