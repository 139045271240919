import React from 'react'
import './WorkBranding.css';
import honedLogo from '../../media/honed/HonedLogo-01.svg';
import screen1 from '../../media/honed/CreateExercise.png';
import screen2 from '../../media/honed/CreateWorkout.png';
import screen3 from '../../media/honed/HangboardHold.png';
import screen4 from '../../media/honed/Timer.png';
import ListText from '../Text/ListText';

export default function WorkBranding(props) {
    const selectedWork = props.selectedWork;

    if (selectedWork === 'honed') {
        return (
            <div className='brandingContainer'>
                <div className='brandingScreens'>
                    <img className='screenShots screenShot1' src={screen1} alt='Honed Screen shot'></img>
                    <img className='screenShots screenShot2' src={screen2} alt='Honed Screen shot'></img>
                    <img className='screenShots screenShot3' src={screen3} alt='Honed Screen shot'></img>
                    <img className='screenShots screenShot4' src={screen4} alt='Honed Screen shot'></img>
                </div>
                <div className='brandingSubContainer'>
                    <h2 className='brandingTitle'>UI and <br></br> branding</h2>
                    <div className='brandingContent'>
                        <div className='brandingLogoImg'>
                            <img className='honedLogo' src={honedLogo} alt='Honed logo'></img>
                            <p className='logoText'>Logo</p>
                        </div>
                        <div className='brandingList'>
                            <ListText
                                listStyle='disk'
                                inputColor='black'
                                listData={[
                                    'User Interface design',
                                    'Logo design',
                                    'Typography',
                                    'Color palette',
                                ]
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}