import React from 'react'
import './SmallSteps.css';
import iconOne from '../../media/One-Icon.svg';
import iconTwo from '../../media/Two-Icon.svg';
import iconThree from '../../media/Three-Icon.svg';
import iconFour from '../../media/Four-Icon.svg';
import iconFive from '../../media/Five-Icon.svg';
import * as constants from '../../Constants';
import CopyText from '../Text/CopyText';

export default function SmallSteps() {
    return (
        <div className='smallStepsContainer'>
            <div className='rowSteps'>
                <div className='iconContainer'>
                    <img className='smallStepIcon' src={iconOne} alt='sketch icon'></img>
                </div>
                <h6 className='numberTitle'>ONE</h6>
                <div className='stepTitles'>
                    <CopyText
                        inputColor={constants.ORANGE}
                        align={'center'}
                        marginTop={'0'}
                    >Ideation and Sketches</CopyText>
                </div>
            </div>
            <div className='rowSteps'>
                <div className='iconContainer'>
                    <img className='smallStepIcon' src={iconTwo} alt='check icon'></img>
                </div>
                <h6 className='numberTitle'>TWO</h6>
                <div className='stepTitles'>
                    <CopyText
                        inputColor={constants.ORANGE}
                        align={'center'}
                        marginTop={'0'}
                    >Wireframe and Usability Tests (UX)</CopyText>
                </div>
            </div>
            <div className='rowSteps'>
                <div className='iconContainer'>
                    <img className='smallStepIcon' src={iconThree} alt='circles icon'></img>
                </div>
                <h6 className='numberTitle'>THREE</h6>
                <div className='stepTitles'>
                    <CopyText
                        inputColor={constants.ORANGE}
                        align={'center'}
                        marginTop={'0'}
                    >UI and branding</CopyText>
                </div>
            </div>
            <div className='rowSteps'>
                <div className='iconContainer'>
                    <img className='smallStepIcon tagIcon' src={iconFour} alt='tag icon'></img>
                </div>
                <h6 className='numberTitle tagNumber'>FOUR</h6>
                <div className='stepTitles'>
                    <CopyText
                        inputColor={constants.ORANGE}
                        align={'center'}
                        marginTop={'0'}
                    >Architecture, Programming, and testing</CopyText>
                </div>
            </div>
            <div className='rowSteps'>
                <div className='iconContainer'>
                    <img className='smallStepIcon' src={iconFive} alt='launch icon'></img>
                </div>
                <h6 className='numberTitle'>FIVE</h6>
                <div className='stepTitles'>
                    <CopyText
                        inputColor={constants.ORANGE}
                        align={'center'}
                        marginTop={'0'}
                    >Launch</CopyText>
                </div>
            </div>
        </div>
    )
}