import React from 'react'
import './WorkIdeation.css';
import honedLogo from '../../media/honed/HonedLogo-01.svg';

export default function WorkIdeation(props) {
    const selectedWork = props.selectedWork;

    if (selectedWork === 'honed') {
        return (
            <div className='ideationContainer'>
                <div className='workLogoImg'>
                    <img className='ideationLogo' src={honedLogo} alt='honed logo'></img>
                </div>
                <h2 className='ideationTitle'>Honed Ideation</h2>
                <p className='ideationText'>An app for rock climbers to customize their hangboard training. Create your own exercise and add exercises with premade hangboards. Set your interval timer, reps, weight and hang!</p>
            </div>
        )
    }
}