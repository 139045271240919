import React from 'react';
import './Contact.css';
import ContactForm from './ContactForm';
import Face from '../components/Face';
import TitleText from '../components/Text/TitleText'
import CopyText from '../components/Text/CopyText';
import * as constants from '../Constants';

export default function Contact(props) {
    const title = props.title || `Let's get together.`;
    const subHeader = props.subHeader || `Have a question or idea you'd like to chat about? We would love to hear about it. Drop us a line using our contact form.
`
    return (
        <div className='ContactContainer' id='contactUs'>
            <Face></Face>
            <div className='ContactTextContainer'>
                <TitleText inputColor={constants.PURPLE}>
                    {title}
                </TitleText>
                <CopyText >

                </CopyText>
                <p className='ContactText'>
                    {subHeader}
                </p>
            </div>
            <div className='contactFormDiv'>
                <ContactForm setShow={props.setShow}></ContactForm>
            </div>
        </div>
    );
}