import React from 'react'
import './ProcessWorkBanner.css';
import { Link } from 'react-router-dom';
import honedImg from '../../media/honed/PlayWorkout.png';
import TitleText from '../Text/TitleText';
import CopyText from '../Text/CopyText';

export default function ProcessWorkBanner() {
    return (
        <div>
            <div className='processWorkContainer'>
                <div className='processWorkRow'>
                    <div className='processWorkColumn'>
                        <TitleText className='processWorkTitle' inputColor={'white'}>Honed</TitleText>
                        <CopyText className='processWorkContent' inputColor={'white'}>See our process in action!</CopyText>
                        <Link className='ourWorkLink' to='work'>
                            <div className='ourWorkButton'>
                                <p className='ourWorkButtonText'>Our work</p>
                            </div>
                        </Link>
                    </div>
                    <div className='processWorkImg'>
                        <img className='honedWorkImg' src={honedImg} alt='honed app'></img>
                    </div>
                </div>
            </div>
        </div>
    )
}