import React, { useState } from 'react'
import './Work.css';
import Navigation from '../components/Navigation';
import WorkMainBanner from '../components/work/WorkMainBanner';
import WorkNavigation from '../components/work/WorkNavigation';
import WorkIdeation from '../components/work/WorkIdeation';
import WorkWireframe from '../components/work/WorkWireframe';
import WorkBranding from '../components/work/WorkBranding';
import WorkTech from '../components/work/WorkTech';
import Contact from '../components/Contact';
import ToastMessage from '../components/ToastMessage';
import Shapes from '../components/Shapes';

export default function Work() {
    const [selectedWork, updateSelectedWork] = useState('honed');
    const [show, setShow] = useState(false);
    return (
        <div>
            <ToastMessage show={show} setShow={setShow}></ToastMessage>
            <Navigation></Navigation>
            <WorkMainBanner></WorkMainBanner>
            <WorkNavigation selectedWork={selectedWork} updateSelectedWork={updateSelectedWork}></WorkNavigation>
            <WorkIdeation selectedWork={selectedWork}></WorkIdeation>
            <WorkWireframe selectedWork={selectedWork}></WorkWireframe>
            <WorkBranding selectedWork={selectedWork}></WorkBranding>
            <WorkTech selectedWork={selectedWork}></WorkTech>
            <div className='moreWorkDiv'>
                <h6 className='moreWork'>CHECK OUT MORE OF OUR WORK</h6>
                <WorkNavigation selectedWork={selectedWork} updateSelectedWork={updateSelectedWork}></WorkNavigation>
            </div>
            <Contact setShow={setShow}></Contact>
            <Shapes page={'work'}></Shapes>
        </div>
    )
}