import React from 'react'
import styled from 'styled-components';

const StyledTitle = styled.h1
    `
    color: ${props => props.inputColor || 'black'};
    margin-top: ${props => props.marginTop || '2vw'};
    text-align: start;
    font-family: 'DM Serif Display', serif;
    font-size: 5vw;
    line-height: 5.5vw;
    z-index: 100;

    @media screen and (min-width: 1200px) {
        font-size: 3vw;
        line-height: 3.5vw;
    }

    @media only screen and (max-width: 500px) {
        font-size: 8vw;
        line-height: 8.5vw;
    }
`

export default function TitleText(props) {
    return (
        <StyledTitle 
            inputColor={props.inputColor} 
            marginTop={props.marginTop}    
        >{props.children}</StyledTitle>
    )
}
