import React from 'react'
import './MainBanner.css';

export default function MainBanner(props) {
    const title = props.title;
    return(
        <div className="BannerContainer">
            {title}
        </div>
    );
}