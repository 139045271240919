import React from 'react'
import styled from 'styled-components';

const StyledTitle = styled.h1
    `
    color: ${props => props.inputColor || 'black'};
    text-align: start;
    font-family: 'Open Sans', sans-serif;
    font-size: 2vw;
    line-height: 2.5vw;
    font-weight: bold;
    letter-spacing: .2vw;

    @media screen and (min-width: 1200px) {
        font-size: 1vw;
        line-height: 1.5vw;
    }

    @media only screen and (max-width: 500px) {
        font-size: 3vw;
        line-height: 3.5vw;
    }
`

export default function SmallTitleText(props) {
    return (
        <StyledTitle inputColor={props.inputColor} >{props.children}</StyledTitle>
    )
}