import React from 'react';
import styled from 'styled-components';

const StyledList = styled.li
    `
    color: ${props => props.inputColor || 'black'};
    font-size: 2vw;
    line-height: 2.5vw;
    text-align: start;


    @media screen and (min-width: 1200px) {
        font-size: 1vw;
        line-height: 1.5vw;
    }

    @media only screen and (max-width: 500px) {
        line-height: 5.5vw;
        font-size: 4vw;
    }
`
const StyledUL = styled.ul
    `
    list-style-type: ${props => props.listStyle || 'none'};
    padding-left: 2vw;

    @media screen and (min-width: 1200px) {
        padding-left: 1vw;
    }

    @media only screen and (max-width: 500px) {
        padding-left: 3vw;
    }
`

export default function ListText(props) {
    const listData = props.listData;
    const ListItems = listData.map((text, index) =>
        <StyledList
            inputColor={props.inputColor}
            key={index}
        >{text}</StyledList>
    )
    return (
        <StyledUL listStyle={props.listStyle}>
            { ListItems}
        </StyledUL>
    )
}


