import React from 'react'
import styled from 'styled-components';
import * as constants from '../../Constants';
import TitleText from '../Text/TitleText';
import CopyText from '../Text/CopyText';
import ListText from '../Text/ListText';

const StyledContainer = styled.div
    `
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`


export default function ThreeSectionText(props) {
    return (
        <StyledContainer>
            <TitleText inputColor={constants.PURPLE}>{props.title}</TitleText>
            <CopyText inputColor={constants.ORANGE}>{props.text}</CopyText>
            <ListText 
                inputColor={constants.PURPLE} 
                listData={props.list}
                listStyle={'disk'}
                />
        </StyledContainer>
    )
}
