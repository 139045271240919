import React from 'react'
import './WorkNavigation.css';

export default function WorkNavigation(props) {
    const selectedWork = props.selectedWork;
    const updateSelectedWork = props.updateSelectedWork;
    return (
        <div className='workNavigationContainer'>
            <h4 
            className={(selectedWork === 'honed') ? 'active' : 'inactive'}
            onClick={() => updateSelectedWork('honed')}
            >Honed</h4>
        </div>
    )
}

/*

            <h4 
            className={(selectedWork === 'cooked') ? 'active' : 'inactive'}
            onClick={() => updateSelectedWork('cooked')}
            >Cooked</h4>

*/