import React, { useState } from 'react';
import Navigation from '../components/Navigation'
import MainBanner from '../components/MainBanner';
import AboutBanner from '../components/AboutBanner';
import WorkBanner from '../components/WorkBanner';
import ProcessBanner from '../components/ProcessBanner';
import Contact from '../components/Contact';
import Shapes from '../components/Shapes';
import ToastMessage from '../components/ToastMessage';
import './Home.css';


export default function Home() {
  const [show, setShow] = useState(false);
  const title = <h1 id='MainText' >A creative <br></br> development team <br></br>delivering apps that<br></br>bloom.</h1>
  return (
      <div className="Home">
        <ToastMessage show={show} setShow={setShow}></ToastMessage>
        <Navigation></Navigation>
        <Shapes page='home'></Shapes>
        <MainBanner title={title}></MainBanner>
        <AboutBanner></AboutBanner>
        <WorkBanner></WorkBanner>
        <ProcessBanner></ProcessBanner>
        <Contact setShow={setShow}></Contact>
        <div style={{ height: 100 }}></div>
      </div>
  );
}