import React, { useState } from 'react'
import './CookedLanding.css';
import Navigation from '../components/Navigation'
import Contact from '../components/Contact';
import Shapes from '../components/Shapes';
import ToastMessage from '../components/ToastMessage';
import TwoColTextLeft from '../components/layout/TwoColTextLeft';
import TwoColTextRight from '../components/layout/TwoColTextRight';
import ThreeSectionText from '../components/layout/ThreeSectionText';
import SingleSectionBanner from '../components/layout/SingleSectionBanner';
import VideoBanner from '../components/layout/VideoBanner';
import SmallStepCard from '../components/layout/SmallStepCard';
import StepsContainer from '../components/layout/StepsContainer';
import FaqCollapse from '../components/layout/FaqCollapse';
import FaqSection from '../components/layout/FaqSection';
import FullBanner from '../components/layout/FullBanner';

import compToPhone from '../media/landingPage/icon.png';
import appFeatures from '../media/landingPage/App-icon2.png';
import mobileNotif from '../media/landingPage/Group-2.png';
import growth from '../media/landingPage/Group-4.png';

//import step1Icon from '../media/landingPage/demo.png';
import step2Icon from '../media/landingPage/features.png';
import step3Icon from '../media/landingPage/Three-Icon.svg';
import step4Icon from '../media/landingPage/Four-Icon.svg';
import step5Icon from '../media/landingPage/One-Icon.svg';
import step6Icon from '../media/landingPage/account.png';
import step7Icon from '../media/landingPage/Five-Icon.svg';

import * as constants from '../Constants';

export default function CookedLanding() {
    const [show, setShow] = useState(false);
    return (
        <div className="Home">
            <ToastMessage show={show} setShow={setShow}></ToastMessage>
            <Navigation></Navigation>
            <Shapes page='landing'></Shapes>
            <FullBanner
                backgroundColor={constants.PURPLE}
                textColor={constants.ORANGE}
                title={<>We create your own mobile app from your recipe blog</>}
            />
            <VideoBanner
                textColor={constants.PURPLE}
                title={<>WATCH A DEMO</>}
                url={'https://youtu.be/4L-b-LwN1Cs'}
            />

            <TwoColTextLeft
                leftContent={
                    <ThreeSectionText
                        title={'Upgrade your user experience'}
                        text={`
                        We’ve user tested (and approved!) the app’s user experience. 
                        It’s easy to use, intuitive, and has more features for your users. 
                        Some possible features that can be included in your app are:
                        `}
                        list={[
                            'Saved recipes',
                            'Collections of saved recipes',
                            'Grocery list created from your recipes',
                            'Personal notes on recipes',
                            'Meal planner',
                            'Better search functionality — users can search by ingredient and how long a recipe takes',
                        ]}
                    />
                }
                imgSrc={appFeatures}
                imgAlt={'App Features'}
                width={'20vw'}
                resWidthFull={'30vw'}
                resWidthSmall={'70vw'}
            />

            <TwoColTextRight
                marginTop={'4vw'}
                rightContent={
                    <ThreeSectionText
                        title={<>Easily control your app's content</>}
                        text={<>
                            The app gets data from the same servers as your website. This means any
                            changes you make to your website will be automatically updated and reflected in the app.
                            It’s a seamless, unified approach to scaling your business.
                        </>}
                        list={[
                            'No additional software to learn',
                            'Maintenance free for you',
                            'Save time and resources',
                        ]
                        }
                    />
                }
                imgSrc={compToPhone}
                imgAlt={'Wordpress to app'}
                width={'25vw'}
            />

            <TwoColTextLeft
                imgSrc={growth}
                imgAlt={'Mobile notifications'}
                width={'15vw'}
                leftContent={
                    <ThreeSectionText
                        title={'Grow your business'}
                        text={`
                        We’ll take care of the technicalities, so you can stay focused on delivering quality content.
                         You’ll gain exposure and reach through the app store market, and because blocking in-app ads in
                          much less common place, you can also grow your bottom line. 
                        `}
                        list={[
                            'App ads are less likely to be blocked = more eyes on your ads',
                            'Smooth integration and launch',
                            'Easily scale your business',
                        ]}
                    />
                }
            />

            <TwoColTextRight
                imgSrc={mobileNotif}
                imgAlt={'Mobile notifications'}
                width={'15vw'}
                rightContent={
                    <ThreeSectionText
                        title={'Gain the app advantage'}
                        text={`
                        Mobile websites have gotten better, but they can't beat the user
                        experience of an app. Some of the benefits of an app are:
                        `}
                        list={[
                            'Brand presence',
                            'Customer engagement',
                            'Push notifications',
                            'User centric features',
                            'Android and iOS compatible',
                        ]}
                    />
                }
            />


            <SingleSectionBanner
                backgroundColor={constants.ORANGE}
                textColor={constants.PURPLE}
                title={'PRICING'}
                leftColTitle={'Pricing'}
                leftColCopy={`
                $45/month
                `}
                listData={[
                    'App Stores Developer accounts not included',
                    'Maintenance & tech support provided',
                ]}
            />
            {
                /*
                <SmallStepCard
                    stepNum={'1'}
                    title={'DEMO'}
                    copy={'We start by showing you around our demo app'}
                    titleColor={constants.PURPLE}
                    copyColor={constants.ORANGE}
                    imgSrc={step1Icon}
                    imgAlt={'Demo Icon'}
                />
                */
            }
            <StepsContainer
                title={'Our Process'}
            >
                <SmallStepCard
                    stepNum={'1'}
                    title={'FEATURES'}
                    copy={'We talk about features you want added to the app or removed'}
                    titleColor={constants.PURPLE}
                    copyColor={constants.ORANGE}
                    imgSrc={step2Icon}
                    imgAlt={'Features Icon'}
                />
                <SmallStepCard
                    stepNum={'2'}
                    title={'BRANDING / UI'}
                    copy={'We create a mockup of your app with your brand look and feel'}
                    titleColor={constants.PURPLE}
                    copyColor={constants.ORANGE}
                    imgSrc={step3Icon}
                    imgAlt={'Branding/UI Icon'}
                />
                <SmallStepCard
                    stepNum={'3'}
                    title={'IMPLEMENTATION & TESTING'}
                    copy={'We begin coding and developing, letting you test it to make sure it\'s working well'}
                    titleColor={constants.PURPLE}
                    copyColor={constants.ORANGE}
                    imgSrc={step4Icon}
                    imgAlt={'Implementation Icon'}
                />
                <SmallStepCard
                    stepNum={'4'}
                    title={'PUBLISH'}
                    copy={'With your sign off, we start work on getting it published'}
                    titleColor={constants.PURPLE}
                    copyColor={constants.ORANGE}
                    imgSrc={step5Icon}
                    imgAlt={'Sign off Icon'}
                />
                <SmallStepCard
                    stepNum={'5'}
                    title={'ACCOUNT SETUP'}
                    copy={'We work with you to setup your accounts on the app stores'}
                    titleColor={constants.PURPLE}
                    copyColor={constants.ORANGE}
                    imgSrc={step6Icon}
                    imgAlt={'Account setup Icon'}
                />
                <SmallStepCard
                    stepNum={'6'}
                    title={'APP RELEASE'}
                    copy={'The app is then released, HURRAY!'}
                    titleColor={constants.PURPLE}
                    copyColor={constants.ORANGE}
                    imgSrc={step7Icon}
                    imgAlt={'App Release Icon'}
                />
            </StepsContainer>

            <FaqSection
                title={'FAQ'}
                color={constants.PURPLE}
                lineColor={constants.PURPLE}
            >
                <FaqCollapse
                    color={constants.PURPLE}
                    lineColor={constants.PURPLE}
                    question={'I thought apps cost 10s of thousands of dollars. Why does this not cost as much?'}
                    answer={`While you get to enjoy the benefits of having an app youd don't own the source code. 
                We will maintain the app and work with you to keep it fresh, but we own the code. That means
                you are sharing the overall cost with other food bloggers that choose to work with us.
                `}
                />
                <FaqCollapse
                    color={constants.PURPLE}
                    lineColor={constants.PURPLE}
                    question={'Will I be responsible for maintaining the code for the app?'}
                    answer={`
                    No, we will work with you to keep the app running and looking good. 
                    We want to make sure users have a positive experience.
                `}
                />
                <FaqCollapse
                    color={constants.PURPLE}
                    lineColor={constants.PURPLE}
                    question={'Will I own the apps code?'}
                    answer={`
                    No, we own the code and reserve the right to use that code in other projects.
                `}
                />
                <FaqCollapse
                    color={constants.PURPLE}
                    lineColor={constants.PURPLE}
                    question={'What if I want to change something after the app has launched?'}
                    answer={`
                    We expect this to be an ongoing partnership and will work with you
                     to keep the app updated and maintained.
                `}
                />
                <FaqCollapse
                    color={constants.PURPLE}
                    lineColor={constants.PURPLE}
                    question={'How long does it take to build my app?'}
                    answer={`
                    We estimate it will take between four to twelve weeks. Every app is different and time frames won't be the same for every app. 
                `}
                />
                <FaqCollapse
                    color={constants.PURPLE}
                    lineColor={constants.PURPLE}
                    question={'My blog is not running on Wordpress. Can you still build me an app?'}
                    answer={`
                    No, our code is currently dependent on Wordpress and will require a Wordpress website for the forseeable future.
                `}
                />
                <FaqCollapse
                    color={constants.PURPLE}
                    lineColor={constants.PURPLE}
                    question={'I just redesigned my brand with a new logo, fonts and colors. Can you update the look and feel of the app to match?'}
                    answer={`
                    We allow one visual update to the app for free once a year. Visually updating the app more than once a year will require a fee.
                `}
                />
                <FaqCollapse
                    color={constants.PURPLE}
                    lineColor={constants.PURPLE}
                    question={'If I download a new Wordpress theme will it work with the app too?'}
                    answer={`
                    When you change your Wordpress theme the apps theme won't change and will continue to work as before. 
                    If you want to change the look of the app let us know and we will work with you to update the app.
                `}
                />
                <FaqCollapse
                    color={constants.PURPLE}
                    lineColor={constants.PURPLE}
                    question={'I no longer want to run my app. What happens when I cancel?'}
                    answer={`
                    You can cancel our service at any time. After termination the app will no longer be functional and you will be required to remove the app's code from the app stores.
                `}
                />
            </FaqSection>
            <Contact
                setShow={setShow}
                title={`Let's build your app`}
                subHeader={
                    `Have a question or want to get started? Drop us a line 
using our contact form`}
            ></Contact>
            <div style={{ height: 100 }}></div>
        </div>
    );
}
