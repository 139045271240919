import React from 'react'
import styled from 'styled-components';

const StyledContainer = styled.div
    `
    display: flex;
    flex-direction: row;
    margin-bottom: 10vw;

    @media only screen and (max-width: 500px) {
        margin-bottom: 0vw;
        flex-direction: column-reverse;
        align-items: center;
        margin-left: 10vw;
        margin-right: 10vw;
    }
`

const RightStyles = styled.div
    `
    width: 50%;
    text-align: left;
    margin-left: 6vw;

    @media screen and (min-width: 1200px) {
        width: 50%;
        text-align: center;
        margin-right: 20vw;
        margin-bottom: 6vw;
    }
    @media only screen and (max-width: 500px) {
        line-height: 1.5vw;
        padding-left: 10%;
        width: 100%;
    }
`

const LeftStyles = styled.div
`
    padding-left: 15%;
    width: 40%;
    margin-top: 5vw;

    @media screen and (min-width: 1200px) {
        display: flex;
    }
    @media only screen and (max-width: 500px) {
        width: 100%;
        padding-left: 0px;
    }
`

const StyledImage = styled.img
    `
    object-fit: contain;
    width: ${props => props.resWidth || 'auto'};
    height: auto;
    @media screen and (min-width: 1200px) {
        width: ${props => props.width || 'auto'};
    }

    @media only screen and (max-width: 500px) {
        width: ${props => props.resWidthSmall || 'auto'};
    }
`

export default function TwoColTextRight(props) {
    return (
        <StyledContainer>
            <LeftStyles>
                <StyledImage
                    src={props.imgSrc}
                    alt={props.imgAlt}
                    width={props.width}
                    resWidth={props.resWidthFull}
                    resWidthSmall={props.resWidthSmall}
                    mobileWidth={props.mobileWidth}
                />
            </LeftStyles>
            <RightStyles>
                {props.rightContent}
            </RightStyles>
        </StyledContainer>
    )
}
