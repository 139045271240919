import React from 'react'
import './AboutBanner.css';
import {Link} from 'react-router-dom';

export default function AboutBanner() {
    return (
        <div className='AboutContainer'>
            <h5 id='aboutText'>We imagine, design, and code, to help <br></br>
            entrepreneurs and small business grow more <br></br>
            meaningful and efficient</h5>

            <div className='aboutButtonContainer'>
                <Link className='aboutButton' to='/about' > About us</Link>
            </div>
        </div>

    );
}