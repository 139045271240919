import React, { useState } from 'react';
import styled from 'styled-components';
import CopyText from '../Text/CopyText';
import { IoChevronForwardOutline, IoChevronDownOutline } from 'react-icons/io5';
const StyledContainer = styled.div
    `
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${props => props.lineColor || 'black'};
    padding-top: 1vw;
    padding-bottom: 1vw;
`

const StyledButton = styled.button
    `
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-right: 5vw;
`
const StyledIcons = styled.div
    `
    padding-left: 2vw;
    pading-right: 2vw;
`

const AnswerSection = styled.div
    `
    width: 80%;
    margin-left: 4vw;

`

export default function FaqCollapse(props) {
    const [collapse, setCollapsed] = useState(true);
    if (collapse) {
        return (
            <StyledContainer lineColor={props.lineColor}>
                <StyledButton onClick={() => setCollapsed(!collapse)}>
                    <CopyText inputColor={props.color}>{props.question}</CopyText>
                    <StyledIcons>
                        <IoChevronForwardOutline />
                    </StyledIcons>
                </StyledButton>
            </StyledContainer>

        )
    }
    else {
        return (
            <StyledContainer>
                <StyledButton onClick={() => setCollapsed(!collapse)}>
                    <strong>
                        <CopyText inputColor={props.color}>{props.question}</CopyText>
                    </strong>
                    <StyledIcons>
                        <IoChevronDownOutline />
                    </StyledIcons>
                </StyledButton>
                <AnswerSection>
                    <CopyText inputColor={props.color}>
                        {props.answer}
                    </CopyText>
                </AnswerSection>
            </StyledContainer>
        )
    }
}
