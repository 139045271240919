import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import About from './pages/About';
import Home from './pages/Home';
import Process from './pages/Process';
import Work from './pages/Work';
import CookedLanding from './pages/CookedLanding';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path='/'>
            <Home/>
          </Route>
          <Route path='/about'>
            <About />
          </Route>
          <Route path='/process'>
            <Process />
          </Route>
          <Route path='/work'>
            <Work/>
          </Route>
          <Route path='/foodBlogApp'>
            <CookedLanding/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
