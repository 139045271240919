import React, { useState } from 'react';
import './About.css';
import Navigation from '../components/Navigation';
import AboutIntro from '../components/AboutIntro';
import AboutContent from '../components/AboutContent';
import Contact from '../components/Contact'
import Shapes from '../components/Shapes';
import ToastMessage from '../components/ToastMessage';

export default function About() {
    const [show, setShow] = useState(false);

    return (
        <div>
            <ToastMessage show={show} setShow={setShow}></ToastMessage>
            <Navigation></Navigation>
            <Shapes page={'about'}></Shapes>
            <AboutIntro></AboutIntro>
            <AboutContent></AboutContent>
            <div className='aboutContact'>
                <Contact setShow={setShow}></Contact>
            </div>
            <div style={{ height: 100 }}></div>
        </div>
    );
}