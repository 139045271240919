import React from 'react';
import './ToastMessage.css';
import Toast from 'react-bootstrap/Toast';

export default function ToastMessage(props) {
    return (
        <Toast 
            className='topToast'
            show={props.show}
            delay={3000}
            onClose={() => props.setShow(false)}
            autohide
            animation={true}
            >
            <div className='toastContainer'>
                <h4 className='toastTitle'>Sent</h4>
                <p className='toastText'>We'll be in touch!</p>
            </div>
        </Toast>
    )
}
