import React from 'react'
import styled from 'styled-components';
import TitleText from '../Text/TitleText';

const StyledContainer = styled.div
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vw;
    margin-top: 5vw;
`

const CenterContainer = styled.div
    `
    display: flex;
    flex-direction: column;
    width: 60%;
    @media only screen and (max-width: 500px) {
        width: 90%;
    }
`

const TitleContainer = styled.div
    `
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${props => props.lineColor || 'black'};
`

export default function FaqSection(props) {
    return (
        <StyledContainer>
            <CenterContainer>
                <TitleContainer lineColor={props.lineColor}>
                    <TitleText inputColor={props.color}>{props.title}</TitleText>
                </TitleContainer>
                {props.children}
            </CenterContainer>
        </StyledContainer>
    )
}
