import React from 'react'
import './Steps.css';
import sketchesGif from '../../media/Process-Sketch.gif';
import wireframeGif from '../../media/SketchtoWireframe.gif';
import designGif from '../../media/WireframetoDesign.gif';
import codeGif from '../../media/honed/Code.gif';
import SmallTitleText from '../Text/SmallTitleText';
import TitleText from '../Text/TitleText';
import CopyText from '../Text/CopyText';
import * as constants from '../../Constants';

export default function Steps() {
    return (
        <div className='stepsContainer'>
            <div className='stepsRow'>
                <div className='stepsRowText'>
                    <div className='stepNumber'>
                        <SmallTitleText
                            inputColor={constants.ORANGE}
                        >ONE</SmallTitleText>
                    </div>
                    <div className='stepTitle'>
                        <TitleText inputColor={constants.PURPLE}> Ideation and <br></br>Sketches </TitleText>
                    </div>
                    <div className='stepText'>
                        <CopyText inputColor={constants.PURPLE}>
                            To understand the project we ask questions, brainstorm, and sketch out how an end user would use the product. We look for potential hurdles, how to avoid or mitigate them and dive into features.
                        </CopyText>
                    </div>
                </div>
                <div >
                    <img className='sketchGif' src={sketchesGif} alt='sketching gif'></img>
                </div>
            </div>
            <div className='stepsRowReverse'>
                <div className='phoneGifContainer'>
                    <img className='phoneGif' src={wireframeGif} alt='wireframe gif'></img>
                </div>
                <div className='stepsRowText'>
                    <div className='stepNumber'>
                        <SmallTitleText
                            inputColor={constants.ORANGE}
                        >TWO</SmallTitleText>
                    </div>
                    <div className='stepTitle'>
                        <TitleText inputColor={constants.PURPLE}> Wireframe and <br></br> usability tests</TitleText>
                    </div>
                    <div className='stepText'>
                        <CopyText inputColor={constants.PURPLE}>
                            With sketches as a guide, we can then create a more refined wireframe, a black and white rendition of the project. Now we can start usability testing to make sure we are building an effective, efficient, and enjoyable experience for users. From our findings with usability tests, we can refine and revise our wireframe.
                        </CopyText>
                    </div>
                </div>
            </div>
            <div className='stepsRow'>
                <div className='stepsRowText'>
                    <div className='stepNumber'>
                        <SmallTitleText
                            inputColor={constants.ORANGE}
                        >THREE</SmallTitleText>
                    </div>
                    <div className='stepTitle'>
                        <TitleText inputColor={constants.PURPLE}> UI and branding</TitleText>
                    </div>
                    <div className='stepText'>
                        <CopyText inputColor={constants.PURPLE}>
                            With a tested, robust wireframe, we can now start creating the user interface, branding, and identity. This includes typography, color, and animation.
                        </CopyText>
                    </div>
                </div>
                <div className='phoneGifContainer'>
                    <img className='phoneGif' src={designGif} alt='design gif'></img>
                </div>
            </div>
            <div className='stepsRowReverse'>
                <div className='phoneGifContainer'>
                    <img className='phoneGif' src={codeGif} alt='code gif'></img>
                </div>
                <div className='stepsRowText'>
                    <div className='stepNumber'>
                        <SmallTitleText
                            inputColor={constants.ORANGE}
                        >FOUR</SmallTitleText>
                    </div>
                    <div className='stepTitle'>
                        <TitleText inputColor={constants.PURPLE}>Architecture, programming, and testing</TitleText>
                    </div>
                    <div className='stepText'>
                        <CopyText inputColor={constants.PURPLE}>
                            With the design completed, we can now start development. The backend is created, user interface implemented, and tests are added to the code.
                        </CopyText>
                    </div>
                </div>
            </div>
            <div className='stepFiveRow'>
                <div className='centerContent'>
                    <div className='stepNumber'>
                        <SmallTitleText
                            inputColor={constants.ORANGE}
                        >FIVE</SmallTitleText>
                    </div>
                    <div className='stepTitle'>
                        <TitleText marginTop={'0'} inputColor={constants.PURPLE}>Launch</TitleText>
                    </div>
                    <div className='stepText'>
                        <CopyText inputColor={constants.PURPLE}>
                            Project is ready to go to market and in users hands.
                        </CopyText>
                    </div>
                </div>
            </div>
        </div>
    )
}