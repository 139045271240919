import React from 'react'
import './WorkWireframe.css';
import sketch1 from '../../media/honed/CreateExercise_Sketch.png';
import sketch2 from '../../media/honed/CreateWorkout_Sketch.png';
import sketch3 from '../../media/honed/PlayWorkout_Sketch.png';
import ListText from '../Text/ListText';

export default function WorkWireframe() {
    return (
        <div className='wireframeRow'>
            <div className='wireframeContainer'>
                <h2 className='wireframeTitle'>Sketches + <br></br> wireframe</h2>
                <h6 className='wireframeSubtitle'>FEATURES</h6>
                <ListText
                    listStyle='disk'
                    inputColor='white'
                    listData={[
                        'Create individual exercises',
                        'Create workout set',
                        'Set interval timer, reps, & weight',
                        'Choose from premade hangboards',
                        'Customize fingers and holds'
                    ]
                    }
                />
            </div>
            <div className='sketchDiv'>
                <img className='sketchs sketch1' src={sketch1} alt='Honed Sketch'></img>
                <img className='sketchs sketch2' src={sketch2} alt='Honed Sketch'></img>
                <img className='sketchs sketch3' src={sketch3} alt='Honed Sketch'></img>
            </div>
        </div>
    )
}