import React from 'react';
import './Navigation.css';
import logo from '../media/OcklloLogo.svg';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function Navigation() {
    const location = useLocation().pathname;
    console.log(location);

    const ActiveLink = (title, path) => {
        if (path === location) {
            return (
                <div className='navLink'>
                    <p className='highlightText'>{title}</p>
                </div>
            )
        }
        else {
            return (
                <Link to={path} className='navLink'>
                    <p className='navLinkText'>{title}</p>
                </Link>
            )
        }
    }
    return (
        <div className='NavContainer'>
            <div className='navLogo'>
                <Link to='/'>
                    <img src={logo} id='navLogo' alt='Ockllo Logo' />
                </Link>
            </div>
            <div className='navLinks'>
                {ActiveLink('Our Work', '/work')}
                {ActiveLink('Process', '/process')}
                {ActiveLink('Food Blog App', '/foodBlogApp')}
                {ActiveLink('About Us', '/about')}
                <HashLink to='#contactUs' className='navLink'>
                    <p className='navLinkText'>Contact</p>
                </HashLink>
            </div>
        </div>
    );
}