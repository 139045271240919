import React, { useState } from 'react'
import './Process.css';
import Navigation from '../components/Navigation';
import ToastMessage from '../components/ToastMessage';
import Shapes from '../components/Shapes';
import ProcessMainBanner from '../components/process/ProcessMainBanner';
import SmallSteps from '../components/process/SmallSteps';
import Steps from '../components/process/Steps';
import ProcessWorkBanner from '../components/process/ProcessWorkBanner';
import Contact from '../components/Contact';

export default function Process() {
    const [show, setShow] = useState(false);
    return (
        <div>
            <ToastMessage show={show} setShow={setShow}></ToastMessage>
            <Navigation></Navigation>
            <ProcessMainBanner></ProcessMainBanner>
            <SmallSteps></SmallSteps>
            <Steps></Steps>
            <ProcessWorkBanner></ProcessWorkBanner>
            <Contact setShow={setShow}></Contact>
            <Shapes page={'process'}></Shapes>
        </div>
    )
}