import React from 'react';
import styled from 'styled-components';
import CopyText from '../Text/CopyText';

const StyledContainer = styled.div
    `
    display: flex;
    flex-direction: column;
    width: 15vw;
    align-items: center;
    padding-left: 3vw;
    padding-right: 3vw;

    @media only screen and (max-width: 500px) {
        width: 50%;
    }
`

const IconContainer = styled.div
    `
    width: 100%;
    height: auto;
    margin-top: ${props => props.marginTop || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    padding-top: 4vw;
`

const ImageContainer = styled.img
`
    position: relative;
    top: ${props => props.imgTop || '0'};
`

export default function SmallStepCard(props) {
    return (
        <StyledContainer>
            <IconContainer>
                <ImageContainer src={props.imgSrc} alt={props.imgAlt} imgTop={props.imgTop} />               
            </IconContainer>
            <strong>
                <CopyText
                    inputColor={props.titleColor}
                    marginBottom={'0px'}
                    align={'center'}
                >{props.stepNum}</CopyText>
            </strong>
            <strong>
                <CopyText
                    marginTop={'0px'}
                    marginBottom={'0px'}
                    inputColor={props.titleColor}
                    align={'center'}
                >{props.title}</CopyText>
            </strong>
            <CopyText align={'center'} inputColor={props.copyColor}>{props.copy}</CopyText>
        </StyledContainer>
    )
}
