import React from 'react'
import styled from 'styled-components';
import TitleText from '../Text/TitleText'
import * as constants from '../../Constants';

const StyledContainer = styled.div
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vw;
`
const TitleContainer = styled.div
    `
    background-color: ${props => props.backgroundColor || 'white'};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Steps = styled.div
    `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }
`

export default function StepsContainer(props) {
    return (
        <StyledContainer>
            <TitleContainer
                backgroundColor={props.titleBackground}
            >
                <TitleText
                    inputColor={constants.PURPLE}
                >
                    {props.title}
                </TitleText>
            </TitleContainer>
            <Steps>
                {props.children}
            </Steps>
        </StyledContainer>
    )
}
