import React from 'react'
import styled from 'styled-components';

const StyledText = styled.p
    `
    color: ${props => props.inputColor || 'black'};
    font-size: 2vw;
    line-height: 2.5vw;
    text-align: ${props => props.align || 'left'};
    margin-bottom: ${props => props.marginBottom || '1em'};
    margin-top: ${props => props.marginTop || '1em'};

    @media screen and (min-width: 1200px) {
        font-size: 1.25vw;
        line-height: 1.75vw;
    }

    @media only screen and (max-width: 500px) {
        line-height: 5.5vw;
        font-size: 4vw;
    }
`

export default function CopyText(props) {
    return (
        <StyledText 
            inputColor={props.inputColor} 
            align={props.align}
            weight={props.weight}
            marginBottom={props.marginBottom}
            marginTop={props.marginTop}
        >{props.children}</StyledText>
    )
}
