import React from 'react';
import styled from 'styled-components';
import TitleText from '../Text/TitleText';
import CopyText from '../Text/CopyText';
import ListText from '../Text/ListText';

const StyledContainer = styled.div
    `
    background-color: ${props => props.backgroundColor || 'white'};
    display: flex;
    flex-direction: column;
    margin-bottom: 2vw;
    @media only screen and (max-width: 500px) {
        margin-top: 10vw;
    }
`

const OneColStyle = styled.div
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5vw;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
    }
`

const LeftColStyle = styled.div
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    @media only screen and (max-width: 500px) {
        width: 80%;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
`

const RightColStyle = styled.div
    `
    display: flex;
    width: 50%;
    padding-top: 1vw;
    justify-content: center;

    @media only screen and (max-width: 500px) {
        width: 100%;
        padding-left: 0;
        justify-content: center;
    }
`


export default function SingleSectionBanner(props) {
    return (
        <StyledContainer backgroundColor={props.backgroundColor}>
            <OneColStyle>
                <LeftColStyle>
                    <TitleText inputColor={props.textColor}>{props.leftColTitle}</TitleText>
                    <CopyText inputColor={props.textColor}>{props.leftColCopy}</CopyText>
                </LeftColStyle>
                <RightColStyle>
                    <ListText
                        inputColor={props.textColor}
                        listData={props.listData}
                        listStyle={'disk'}
                    ></ListText>
                </RightColStyle>
            </OneColStyle>
        </StyledContainer>
    )
}
