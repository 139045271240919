import React, { useState } from 'react';
import './ContactForm.css';

export default function ContactForm(props) {
    const [message_html, setMessage] = useState('');
    const [from_name, setName] = useState('');
    const [from_contact, setContact] = useState('');

    return (
        <div className='contactFormContainer'>
            <form className='contactForm'>
                <div className='inputDiv'>
                    <label>
                        Your Name:
                    <input
                            type='text'
                            value={from_name}
                            onChange={(event) => { setName(event.target.value) }}
                        />
                    </label>
                </div>
                <div className='inputDiv'>
                    <label >
                        Your Email:
                    <input
                            type='email'
                            value={from_contact}
                            onChange={(event) => { setContact(event.target.value) }}
                        />
                    </label>
                </div>
                <div className='inputDiv'>
                    <label>
                        Your Message:
                    </label>
                    <textarea
                        id='contactForm'
                        name='contactForm'
                        className='contactText'
                        onChange={(event) => setMessage(event.target.value)}
                        value={message_html}
                    >

                    </textarea>
                </div>
                <div className='formButtonContainer'>
                    <input
                        type='button'
                        value='Send'
                        className='submitButton'
                        onClick={() => 
                            handleSubmit(
                                { message_html, from_name, from_contact },
                                 props.setShow,
                                 setMessage,
                                 setContact,
                                 setName
                                 )}
                    />
                </div>
            </form>
        </div>
    );
}

function handleSubmit(variables, setShow, setMessage, setContact, setName) {
    const templateId = 'template_yxOq9sUC_clone';
    window.emailjs.send(
        'gmail', 
        templateId,
        variables
    );
    setShow(true);
    setMessage('');
    setContact('');
    setName('');
}
