import React from 'react'
import './WorkTech.css';
import honedGif1 from '../../media/honed/VideoHangboard.gif';
import honedGif2 from '../../media/honed/VideoTimer.gif';
import ListText from '../Text/ListText';

export default function WorkBranding(props) {
    const selectedWork = props.selectedWork;

    if (selectedWork === 'honed') {
        return (
            <div className='techContainer'>
                <div className='techTextContainer'>
                    <div className='techContent'>
                        <h2 className='techTitle'>Tech</h2>
                        <div className='techList'>
                            <ListText
                                listStyle='disk'
                                inputColor='black'
                                listData={[
                                    'React Native',
                                    'Component testing',
                                    'Responsive layout',
                                ]
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='techImgContainer'>
                    <img className='fullGif1' src={honedGif1} alt='gif of honed app'></img>
                    <img className='fullGif2' src={honedGif2} alt='gif of honed app'></img>
                </div>
            </div>
        )
    }
}