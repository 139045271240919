import React from 'react'
import './Shapes.css';
import HalfCircleOutline from '../media/HalfCircleOutline.svg';
import HalfCircle from '../media/HalfCircle.svg';

export default function Shapes(props) {
    if (props.page === 'home') {
        return (
            <div>
                <div id='rectangleOne'>
                </div>
                <div id='circleOutlineOne'>

                </div>
                <div id='halfCircleOne'>

                </div>
                <div id='rectangleTwo'>

                </div>
                <div id='circleOutlineTwo'>

                </div>
                <div id='halfCircleTwo'>

                </div>
            </div>
        )
    }

    else if(props.page ==='work')
    {
        return(
            <div>
                <div id='rectangleOne'>
                </div>
                <div id='circleOutlineOne'>

                </div>
            </div>
        )
    }

    else if(props.page === 'process')
    {
        return(
            <div>
                <div id='rectangleOne'>
                </div>
                <div id='circleOutlineOne'>

                </div>
                <div id='halfCircleOne'>
                </div>
                <div id='rectangleTwo'>

                </div>
                <div id='circleOutlineTwo'>

                </div>
                <div id='halfCircleThree'>

                </div>
                <div id='bigSolidCircle'></div>
                <div id='circleOutlineThree'></div>
                <div id='circleOutlineFour'></div>
            </div>
        )
    }

    else if(props.page === 'landing')
    {
        return(
            <div>
                <div id='rectangleOne'>
                </div>
                <div id='circleOutlineOne'>
                    <img className='imgScale' src={HalfCircleOutline} alt={'half circle outline'} />
                </div>
                <div id='halfCircleOne'>

                </div>
                <div id='rectangleTwo'>

                </div>
                <div id='circleOutlineTwo'>

                </div>
                <div id='halfCircleThree'>

                </div>
                <div id='bigSolidCircle2'>
                    <img className='imgScale' src={HalfCircle} alt={'half circle'}/>
                </div>
                <div id='circleOutlineThree2'></div>
                <div id='circleOutlineFour2'></div>
            </div>
        )
    }
    else
    {
        return (
            <div>
                <div id='rectangleOneAbout'>
                </div>
                <div id='circleOutlineOne'>

                </div>
                <div id='halfCircleOne'>

                </div>
                <div id='rectangleTwoAbout'>

                </div>
                <div id='circleOutlineTwo'>

                </div>
                <div id='halfCircleTwo'>

                </div>
            </div>
        )
    }
}