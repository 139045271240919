import React from 'react'
import './AboutIntro.css';
import aboutImg from '../media/AboutImg.jpg';
import TitleText from '../components/Text/TitleText';
import * as constants from '../Constants';

export default function AboutIntro() {
    return (
        <div className='aboutIntroContainer'>
            <div className='aboutIntroTextDiv'>
                <div className='aboutIntroText'>
                    <TitleText
                        inputColor={constants.PURPLE}
                    >
                        A husband-wife, <br /> designer-developer duo.
                </TitleText>
                </div>
            </div>
            <div className='aboutPhotoContainer'>
                <div className='aboutIntroPhoto'>
                    <img className='aboutImg' src={aboutImg} alt='Two faces'></img>
                </div>
            </div>
        </div>
    )
}