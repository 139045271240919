import React from 'react'
import './ProcessBanner.css';
import { Link } from 'react-router-dom';
import sketchGif from '../media/Process-Sketch.gif';

export default function ProcessBanner() {
    return (
        <div className='ProcessContainer'>
            <h6 className='SmallTitlePurple'>PROCESS</h6>
            <div className='processContentContainer'>
                <div className='ProcessContent'>
                    <h2>Performance and <br></br>beauty that work.</h2>
                    <p className='ProcessText'>We love creating something people love. How do we do it?<br></br>
                  Performance and beauty.
                 </p>
                    <div className='PurpleBtnDiv'>
                        <Link className='AboutButton' to='process'>Our Process</Link>
                    </div>
                </div>
                <div className='sketchGifContainer'>
                    <img className='processSketchGif' src={sketchGif} alt='sketch gif' ></img>
                </div>
            </div>
        </div>
    );
}