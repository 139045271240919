import React from 'react'
import styled from 'styled-components';

const StyledContainer = styled.div
    `
    display: flex;
    flex-direction: row;
    margin-bottom: 10vw;
    margin-top: ${props => props.marginTop || '0px'};

    @media only screen and (max-width: 500px) {
        margin-bottom: 0vw;
        flex-direction: column;
        align-items: center;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 14vw;
    }
`

const LeftStyles = styled.div
    `
    width: 40%;
    text-align: left;
    padding-left: 15%;

    @media screen and (min-width: 1200px) {
        text-align: center;
        padding-right: 5vw;
    }
    @media only screen and (max-width: 500px) {
        line-height: 1.5vw;
        padding-left: 10%;
        width: 100%;
    }
`

const RightStyles = styled.div
    `
    width: 40%;
    margin-top: 5vw;

    @media screen and (min-width: 1200px) {
        display: flex;
    }
    @media only screen and (max-width: 500px) {
        width: 100%;
    }
`

const StyledImage = styled.img
    `
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    @media screen and (min-width: 1200px) {
        width: ${props => props.width || 'auto'};
    }
`


export default function TwoColTextLeft(props) {
    return (
        <StyledContainer marginTop={props.marginTop}>
            <LeftStyles>
                {props.leftContent}
            </LeftStyles>
            <RightStyles>
                <StyledImage
                    src={props.imgSrc}
                    alt={props.imgAlt}
                    width={props.width}
                />
            </RightStyles>
        </StyledContainer>
    )
}
