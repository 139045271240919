import React from 'react';
import styled from 'styled-components';
import TitleText from '../Text/TitleText';

const StyledContainer = styled.div
    `
    background-color: ${props => props.backgroundColor || 'white'};
    display: flex;
    flex-direction: column;
    margin-bottom: 2vw;
    z-index: -100;
    @media only screen and (max-width: 500px) {
        margin-top: 10vw;
    }
`

const OneColStyle = styled.div
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2vw;
    @media only screen and (max-width: 500px) {
        flex-direction: column;
    }
`
const TopStyle = styled.div
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    @media only screen and (max-width: 500px) {
        width: 80%;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
`

export default function FullBanner(props) {
    return (
        <StyledContainer backgroundColor={props.backgroundColor}>
            <OneColStyle>
                <TopStyle>
                    <TitleText inputColor={props.textColor}>{props.title}</TitleText>
                </TopStyle>
            </OneColStyle>
        </StyledContainer>
    )
}