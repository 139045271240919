import React from 'react'
import './WorkMainBanner.css';
import TitleText from '../Text/TitleText';
import * as constants from '../../Constants';

export default function WorkMainBanner() {
    return (
        <div className='WorkMainBanner'>
            <div className='WorkMainBannerText'>
                <TitleText inputColor={constants.ORANGE}>Our Work</TitleText>
            </div>
        </div>
    )
}