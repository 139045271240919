import React from 'react'
import './Face.css';

export default function Face() {
    return (
        <div className='facePosition'>
            <div className='faceShapes'>
                <div className='eyes'>
                    <div className='rightEye'></div>
                    <div className='leftEye'></div>
                </div>
                <div className='mouth'></div>
            </div>
        </div>
    )
}