import React from 'react'
import './AboutContent.css';

export default function AboutContent() {
    return (
        <div className='aboutContentContainer'>
            <div className='aboutLeftDiv'>
                <div className='aboutLeft leftMargin'>
                    <h2 className='orangeText'>
                        We are a nimble team, with passion and expertise in designing and developing software.
                    </h2>
                </div>
                <div className='aboutRight'>

                </div>
            </div>
            <div className='aboutRightDiv'>
                <div className='aboutLeft topMargin'>
                    <div className='ockShapes'>
                        <div className='oShape'>
                        </div>
                        <div className='cShape'>
                        </div>
                        <div className='kShape'>
                            <div className='topTriShape'></div>
                            <div className='bottomTriShape'></div>
                        </div>
                    </div>
                </div>
                <div className='aboutRight aboutRightTop'>
                    <p className='purpleText smallAboutText'>
                        Maggie (designer) and Michael (developer) are the husband-wife team behind Ockllo. We specialize in app and website design and development. We embrace an iterative and user driven approach to create intuitive, beautiful work.
                    </p>
                </div>
            </div>
            <div className='aboutLeftDiv'>
                <div className='aboutLeft aboutLeftBottom leftMargin'>
                    <h2>
                        We value a working relationship with kindness and respect.
                    </h2>
                </div>
                <div className='aboutRight'>
                    <div className='lloShapes'>
                        <div className='lShape'></div>
                        <div className='lShape'></div>
                        <div className='oShape'></div>
                    </div>
                </div>
            </div>
            <div className='aboutRightDiv'>
                <div className='aboutLeft'></div>
                <div className='aboutRight aboutRightBottom'>
                    <p className='purpleText smallAboutText'>We enjoy working with other small businesses and entreprenuers to expand their business through software and design. We love clients who have goals in mind and value our experience and expertise.</p>
                </div>
            </div>
            <div className='aboutBottomText'>
                <p className='SmallTitle orangeText'> AS A SMALL TEAM, WHEN YOU HAVE OUR ATTENTION, YOU HAVE OUR FULL ATTENTION.</p>
            </div>
        </div>
    )
}