import React from 'react';
import styled from 'styled-components';
import SmallTitleText from '../Text/SmallTitleText';
import ReactPlayer from 'react-player';

const StyledContainer = styled.div
    `
    background-color: ${props => props.backgroundColor || 'white'};
    display: flex;
    flex-direction: column;
    margin-bottom: 2vw;
    @media only screen and (max-width: 500px) {
        margin-top: 10vw;
    }
`

const OneColStyle = styled.div
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5vw;
    @media only screen and (max-width: 500px) {
        flex-direction: column;
    }
`

const TopStyle = styled.div
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    @media only screen and (max-width: 500px) {
        width: 80%;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
`

const BottomStyle = styled.div
    `
    display: flex;
    width: 80%;
    padding-top: 1vw;
    justify-content: center;

    @media only screen and (max-width: 500px) {
        width: 100%;
        padding-left: 0;
        justify-content: center;
    }
`


export default function VideoBanner(props) {
    return (
        <StyledContainer backgroundColor={props.backgroundColor}>
            <OneColStyle>
                <TopStyle>
                    <SmallTitleText inputColor={props.textColor}>{props.title}</SmallTitleText>
                </TopStyle>
                <BottomStyle>
                    <ReactPlayer url={props.url} controls={true} width={1000} height={562.5}/>
                </BottomStyle>
            </OneColStyle>
        </StyledContainer>
    )
}
